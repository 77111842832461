<template>
  <history-list :documentId="id" />
</template>

<script>
export default {
  components: {
    HistoryList: () => import('@/pages/history/history-list')
  },
  props: {
    id: String
  }
}
</script>
